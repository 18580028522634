import { FiltersType } from '../../types/filters';
import { FeedType } from '../../types/feed';
import { QueryObjectType } from '../../utils/parseQuery';

export const mapUrlParams = (filters: QueryObjectType): FeedType => (
	{
		currency: filters.currency,
		limit: filters.limit,
		googleFeedUrl: filters.googleFeedUrl,
		fbFeedUrl: filters.fbFeedUrl,
		locale: filters.locale,
		utm_campaign: filters.utm_campaign,
		utm_content: filters.utm_content,
		utm_medium: filters.utm_medium,
		utm_source: filters.utm_source,
	}
);

export const mapFilters = (filters: QueryObjectType): FiltersType => (
	{
		'active-warranty': filters['active-warranty'],
		'air-conditioning': filters['air-conditioning'],
		'auxiliary-heating': filters['auxiliary-heating'],
		'auxiliary-heating-any': filters['auxiliary-heating-any'],
		'car-style': filters['car-style'],
		color: filters.color,
		condition: filters.condition,
		'cruise-control': filters['cruise-control'],
		'cruise-control-any': filters['cruise-control-any'],
		'cubic-capacity-from': filters['cubic-capacity-from'],
		'cubic-capacity-to': filters['cubic-capacity-to'],
		display: filters.display,
		'door-count': filters['door-count'],
		drive: filters.drive,
		'emission-class': filters['emission-class'],
		feature: filters.feature,
		'fuel-consumption-combined-to': filters['fuel-consumption-combined-to'],
		'fuel-type': filters['fuel-type'],
		'headlights-type': filters['headlights-type'],
		'interior-color': filters['interior-color'],
		'interior-material': filters['interior-material'],
		make: filters.make,
		'mileage-from': filters['mileage-from'],
		'mileage-to': filters['mileage-to'],
		'model': ([...(filters['model'] || [])] as (string[] | number[])),
		'model-group': filters['model-group'],
		'number-of-airbags-from': filters['number-of-airbags-from'],
		'number-of-gears': filters['number-of-gears'],
		'number-of-owners-to': filters['number-of-owners-to'],
		'number-of-seats-from': filters['number-of-seats-from'],
		'origin-country': filters['origin-country'],
		country: filters.country,
		'parking-camera': filters['parking-camera'],
		'parking-sensors': filters['parking-sensors'],
		'power-from': filters['power-from'],
		'power-to': filters['power-to'],
		'price-level-from': filters['price-level-from'],
		'price-level-to': filters['price-level-to'],
		'registration-date-from': filters['registration-date-from'],
		'registration-date-to': filters['registration-date-to'],
		seller: filters.seller,
		'sliding-doors': filters['sliding-doors'],
		source: filters.source,
		status: filters.status,
		sunroof: filters.sunroof,
		'tailgate-opening': filters['tailgate-opening'],
		'trailer-coupling': filters['trailer-coupling'],
		'trailer-coupling-any': filters['trailer-coupling-any'],
		transmission: filters.transmission,
		'vat-reclaimable': filters['vat-reclaimable'],
		search: filters.search,
		page: filters.page,
		hidden: filters.hidden,
		'advertising-rules-apply': filters['advertising-rules-apply'],
		'advertising-rules-user-country': filters['advertising-rules-user-country'],
		'advertising-rules-user-dealer': filters['advertising-rules-user-dealer'],
		'available-in-feed': filters['available-in-feed'],
		'available-in-feed-computed': filters['available-in-feed-computed'],
		'advertised-car-id': filters['advertised-car-id'],
		'installment-from': filters['installment-from'],
		'installment-to': filters['installment-to'],
		'market-score-to': filters['market-score-to'],
		'market-score-from': filters['market-score-from'],
		'market-score-interval': filters['market-score-interval'],
		'model-percentage': filters['model-percentage'],
		'registration-month-from': filters['registration-month-from'],
		'registration-month-to': filters['registration-month-to'],
	}
);
